<template>
    <div v-if="show">
   
    <v-card dense>
        <v-toolbar>
            GAME GROSS OVERALL
            <v-spacer/>
            <!-- <v-btn class="mr-2" small color="info" @click="getTransactions()">TODAY</v-btn> -->
               <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="From Date"
                                 :value="from_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
               </span>
                <span style="width: 200px; margin-top: 24px;" class="ml-2"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="To Date"
                                 :value="to_date"
                                 readonly
                                 filled
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, getTransactions()" />
                    </v-menu>
               </span>
               <v-icon right color="primary" @click="dlToCsv()">mdi-download</v-icon>
        </v-toolbar>
        <v-card-text>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
            <p class="text-center">
                GAME GROSS OVERALL REPORT<br />
Draw Period: OVERALL<br />
Date Covered: {{$moment(from_date).format('YYYY-MMM-DD')}} - {{$moment(to_date).format('YYYY-MMM-DD')}} 
            </p>
            <table class="va-table def-font" id="over-table">
                <tr>
                    <th>GAME</th>
                    <th class="text-right">GROSS</th>
                    <th class="text-right">UNIT HITS</th>
                    <th class="text-right">AMOUNT HITS</th>
                </tr>
                 <tr v-for="(item,index) in PERGAMES" :key="index" >
                    <td class="border_bot">{{item.game}} </td>
                    <td class="text-right border_bot">{{$money(item_gross(item.data))}}</td>
                    <td class="text-right border_bot">{{$money(item_hits(item.data))}}</td>
                    <td class="text-right border_bot">{{$money(item_payout(item.data))}}</td>
                </tr>
                <tr>
                     <th  class="text-right" style="background: #efefef;">TOTAL:</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALGROSS)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALHITS)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALPAYOUT)}}</th>
                </tr>

               
               
            </table>
<!-- <table class="va-table def-font mt-10" id="over-table2">
             <tr>
                     <th  class="text-center text-h6" style="background: #efefef;">TOTAL GROSS<br/>  {{$money(TOTALGROSS)}}</th>
                    <th class="text-center text-h6"  style="background: #efefef;">TOTAL COMMISSION<br/>{{$money(TOTALCOMMISSION)}}</th>
                    <th class="text-center text-h6"  style="background: #efefef;">NET<br/> {{$money(TOTALNET)}}</th>
                    <th class="text-center text-h6"  style="background: #efefef;">TOTAL PAYOUT<br/> {{$money(TOTALPAYOUT)}}</th>
                    <th class="text-center text-h6"  style="background: #efefef;">REVENUE<br/> {{$money(TOTALNET-TOTALPAYOUT)}}</th>
                </tr>
</table> -->
        </v-card-text>     
    </v-card>
 <v-row>
    <v-col cols="12" class="mt-2">
             <v-card dense>
        <v-toolbar>
            OPERATION'S SUMMARY
           <v-spacer/>
          
        </v-toolbar>
        <v-card-text>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
            
             <table class="va-table def-font" id="over-table">
                <tr>
                 
                    <th>COMMISSION</th>
                    <th class="text-right">GROSS</th>
                    <th class="text-right">COMMISSION</th>
                    <th class="text-right">PAYOUT</th>
                    <th class="text-right">REVENUE</th>
                </tr>
                 <tr v-for="(item,index) in COMMISSIONPERGROUP" :key="index" >
                  <td class="border_bot">{{item.commission}}%</td>
                  <td class="text-right border_bot">{{$money(item_gross(item.data))}}</td>
                  <td class="text-right border_bot">{{$money(item_gross(item.data) * ($IsNum(item.commission)/100))}}</td>
                  <td class="text-right border_bot">{{$money(item_win(item.data))}}</td>
                  <td class="text-right border_bot font-weight-bold">{{$money(item_gross(item.data) - item_win(item.data) - (item_gross(item.data) * ($IsNum(item.commission)/100)))}}</td>
                </tr>
                <tr>
                    
                     <th  class="text-right" style="background: #efefef;">TOTAL:</th>
                      <th class="text-right"  style="background: #efefef;">{{$money(TOTALGROSS)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALCOMM)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALPAYOUT)}}</th>
                    <th class="text-right"  style="background: #efefef;" :class="item_revenvue(TOTALGROSS,TOTALCOMM,TOTALPAYOUT)>-1?'text-success':'text-error'">{{$money(item_revenvue(TOTALGROSS,TOTALCOMM,TOTALPAYOUT))}}</th>
                </tr>
               
            </table>
          
        </v-card-text>     
    </v-card>
        </v-col>
</v-row> 
   
  </div>
</template>

<script>

export default {
  props: {
      show:{
          type: Boolean,
          default: false
      }
  },
  data: () => ({
        loading: false,
        from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
        commissiongroup:[],
        wingroup:[],
        items:[]
       
    }),
    watch:{
        show(v) {
            if(v){
                this.from_date = this.$moment().format("YYYY-MM-DD")
                this.to_date = this.from_date
            }
        }
    },
    computed:{
        PERGAMES() {
            var dataArr = []
            var bygames = this.$groupBy('game')
            for (const [key, value] of Object.entries( bygames(this.items))) {
                    var item = { game: key, data:value}
                   dataArr.push(item)
            }
            return dataArr
        },
    TOTALCOMM() {
        var total = this.COMMISSIONPERGROUP.reduce((res,item)=>{
                res =  res+this.item_comm(item)
                return res
        },0)
        return total
      },
        PERCOMMISSIONSDATA() {
            var data = []
            for(var i = 0; i <this.commissiongroup.length; i++){
                for(var w = 0; w <this.wingroup.length; w++){
                    let temp = {
                        draw_date: this.commissiongroup[i].draw_date,
                        commission: this.commissiongroup[i].commission,
                        gross: this.commissiongroup[i].gross,
                        draw_win_amount: 0,
                        hits: 0
                    }
                    if(this.commissiongroup[i].commission == this.wingroup[w].commission) {
                            temp.draw_win_amount = this.wingroup[w].win_amount
                            temp.hits = this.wingroup[w].gross
                    }
                    data.push(temp)
                }
            }
            return data
        },
        TOTALGROSS() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.gross),0)
            return total
        },
         TOTALHITS() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.amount_of_winning),0)
            return total
        },
         TOTALPAYOUT() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.payout),0)
            return total
        },
        COMMISSIONPERGROUP(){
            var bycommission = this.$groupBy('commission')
            let obj = bycommission(this.commissiongroup)
            let group = []
            Object.keys(obj).forEach(key => {
                group.push({commission:key, data:obj[key]})
            });
        return group
      },
       TOTALCOMMISSION() {
            var total = this.COMMISSIONPERGROUP.reduce((res,item)=>res += this.$IsNum(item.comm_amount),0)
            return total
        },
        TOTALNET() {
            var total = this.COMMISSIONPERGROUP.reduce((res,item)=>res += this.$IsNum(item.net),0)
            return total
        },
         TOTALREVENUE() {
            var total = this.COMMISSIONPERGROUP.reduce((res,item)=>res += this.$IsNum(item.revenue),0)
            return total
        },
       COMTOTALGROSS() {
            var total = this.commissiongroup.reduce((res,item)=>res += this.$IsNum(item.gross),0)
            return total
        },
         COMTOTALHITS() {
            var total = this.COMMISSIONPERGROUP.reduce((res,item)=>res += this.$IsNum(item.hits * item.draw_win_amount),0)
            return total
        }
    },
  methods: {
      item_revenvue(gross, comm, hits){
        return gross - comm - hits
      },
       item_comm(line) {
        let commission = this.item_gross(line.data) * (this.$IsNum(line.commission)/100)
        return commission
      },
      item_gross(items) {
          return items.reduce((res,item)=>res += this.$IsNum(item.gross),0)
      },
       item_hits(items) {
          return items.reduce((res,item)=>res += this.$IsNum(item.amount_of_winning),0)
      },
       item_payout(items) {
          return items.reduce((res,item)=>res += this.$IsNum(item.payout),0)
      },
      item_win(items) {
          return items.reduce((res,item)=>res += this.$IsNum(item.win_amount),0)
      },
      calculate(percent, gross){
        var comm = this.$IsNum(gross) * (this.$IsNum(percent)/100)
        return  comm
      },
        getTransactions(){
            this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.from_date
            param.to_date = this.to_date
            param.Method = "overall"
             this.$http.post("report/transactions",param).then(response => {
                response.data.transactions !=null?this.items = response.data.transactions:this.items =[]
                this.loading = false
               this.getPerCom()
          }).catch(e => {
            console.log(e.data)
          })
        },
        getPerCom(){
            this.loading = true
            this.commissiongroup =[]
            var param = {}
            param.from_date = this.from_date
            param.to_date = this.to_date
            param.Method = "daily_coordinator_summary"
             this.$http.post("report/transactions",param).then(response => {
                response.data.items !=null?this.commissiongroup = response.data.items:this.commissiongroup =[]
                this.loading = false
              
          }).catch(e => {
            console.log(e.data)
          })
        }, 
        dlToCsv(){
            this.DownloadToCsv("over-table", 'Per-draw-' + this.from_date + '-to-' + this.to_date)
        }
  }
}
</script>
