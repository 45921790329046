<template>
    <v-dialog
    v-model="show"
    persistent
    width="400">
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-ticket</v-icon><span class="va-underline">Cashout</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
           
           
               
            <div>
                  {{cashout.items.game}} - {{cashout.items.draw_date}}
                  <v-divider class="mt-2 mt-b" /> 
                  COMM: {{$money(cashout.items.commission)}}
                  <v-divider class="mt-2 mt-b" /> 
                  WINS: {{$money(cashout.items.wins)}}
                  <v-divider class="mt-2 mt-b" /> 
                  <strong>TOTAL:  {{$money(cashout.total)}}</strong>
            <v-alert
            class="mt-2"
                text
              >
                <v-divider class="mt-b" /> 
                <strong>Bank/eWallet:  {{cashout.bank}}</strong>
                <v-divider class="mt-2 mt-b" /> 
                <strong>Account Name:  {{cashout.account_name}}</strong>
                <v-divider class="mt-2 mt-b" /> 
                <strong>Account Number:  {{cashout.account_number}}</strong>
                <v-divider class="mt-2 mt-b" /> 
          </v-alert>
            </div>
           
               <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
               
                <v-select
                 v-model="cashout.status" 
                 label="Status"
                 :rules="nameRules"
                    required
                 outlined
                 :items="['Pending','Completed']"/>
                 <!-- <v-select
                    v-model="cashout.remarks"
                    label="Add Remarks"
                    outlined
                    :items="reasons"/> -->
                 
                
               </v-form>
               <v-divider/>
          </v-card-text>
          <v-card-actions>
               <v-btn small color="warning" @click="emitToParent('close')">
                 Close
              </v-btn>
              <v-spacer/>
              <v-btn small color="error" @click="validate()">
                  Save
              </v-btn>
          </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      },
      cashout:{
          type: Object,
          default: ()=>{}
      }
  },
  data () {
      return {
        reasons: [
        "",
            "Invalid Gcash account. Please cancel this request and try again.",
            "Service Unavailable. Please cancel this request and try again later.",
            "Your GCASH account reached the limit. Can't transfer this time!",
            "Insuficient balance."
        ],
          valid: true,
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
     show(val) {
         if(val) {
             this.entry = {}
         }
     }
  },
  computed:{
   
  },
  methods: {
    validate () {
    if (this.$refs.form.validate()) {
        this.update()
    }
    },
   emitToParent (action) {
      this.$emit('DialogEvent',action )
    },
    update(){
         this.$http.post("admin_update_cashout",this.cashout).then(response => {
             console.log(response.data)
              if(!response.data.status) {
                this.VA_ALERT('error', (response.data.message))
              } else {
                this.VA_ALERT('success', (response.data.message))
              }
                 this.$emit('DialogEvent', "close")
          }).catch(e => {
            console.log(e.data)
          })
    }
  }
}
</script>
