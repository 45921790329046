/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
      path: '/dashboard',
      name: 'Dashboard',
      view: 'Dashboard'
    },
    {
      path: '/runner',
      name: 'Runner',
      view: 'Runner'
    },
    {
      path: '/login',
      name: 'Login',
      view: 'Login'
    },
    {
      path: '/users',
      name: 'User Management',
      view: 'accounts/Users'
    },
    {
      path: '/game-settings',
      name: 'Game Settings',
      view: 'accounts/Settings'
    },
    {
      path: '/game-login',
      name: 'Log in',
      view: 'games/Login'
    },
    {
      path: '/game-dashboard',
      name: 'Game Dashboard',
      view: 'games/Dashboard'
    },
    {
      path: '/3d-game-setup',
      name: 'SWERTRES 3D',
      view: 'game-setup/3D'
    },
    {
      path: '/2d-game-setup',
      name: '2D',
      view: 'game-setup/2D'
    },
    {
      path: '/4d-game-setup',
      name: '4D Game',
      view: 'game-setup/4D'
    },
    {
      path: '/swer3-game-setup',
      name: 'SWER3 Game',
      view: 'game-setup/SWER3'
    },
    {
      path: '/last-two-game-setup',
      name: 'LAST TWO Game',
      view: 'game-setup/LastTwo'
    },
    {
      path: '/3dlocal-game-setup',
      name: '3D LOCAL Game',
      view: 'game-setup/3DLOCAL'
    },
    {
      path: '/2dlocal-game-setup',
      name: '2D LOCAL Game',
      view: 'game-setup/2DLOCAL'
    },
    {
      path: '/pick3-game-setup',
      name: 'PICK 3 Game',
      view: 'game-setup/PICK3'
    },
    {
      path: '/stlpares-game-setup',
      name: 'STL PARES',
      view: 'game-setup/STLPARES'
    },
    {
      path: '/stllocal-game-setup',
      name: 'STL LOCAL',
      view: 'game-setup/STLLOCAL'
    },
    {
      path: '/3d-game',
      name: '3D GAME',
      view: 'games/3D-game'
    },
    {
      path: '/2d-game',
      name: '2D GAME',
      view: 'games/2D-game'
    },
    {
      path: '/4d-game',
      name: '4D GAME',
      view: 'games/4D-game'
    },
    {
      path: '/swer3-game',
      name: 'SWER3 GAME',
      view: 'games/Swer3-game'
    },
    {
      path: '/swer2-game',
      name: 'SWER2 GAME',
      view: 'games/Swer2-game'
    },
    {
      path: '/3dlocal-game',
      name: '3D-LOCAL GAME',
      view: 'games/3DLOCAL-game'
    },
    {
      path: '/2dlocal-game',
      name: '2D-LIVE GAME',
      view: 'games/2DLOCAL-game'
    },
    {
      path: '/pick3-game',
      name: 'PICK 3 GAME',
      view: 'games/PICK3-game'
    },
    {
      path: '/reports',
      name: 'Daily Reports',
      view: 'reports/Reports'
    },
    {
      path: '/bet-logs',
      name: 'Bet Logs',
      view: 'reports/Bets'
    },
    {
      path: '/void-tickets',
      name: 'Void Tickets',
      view: 'reports/Voids'
    },
    //New games
    {
      path: '/2d-game-stl',
      name: 'STL',
      view: 'game-setup/STLLOCAL'
    },
    {
      path: '/2d-game-province',
      name: 'STL Province',
      view: 'game-setup/2D-Province'
    },
    {
      path: '/2d-game-city',
      name: '2D STL ILO-ILO CITY',
      view: 'game-setup/2D-City'
    },
    {
      path: '/3d-game-city',
      name: '3D STL ILO-ILO CITY',
      view: 'game-setup/3D-City'
    },
    {
      path: '/3d-zam-city',
      name: ' 3D-ZAM-CITY',
      view: 'game-setup/3D-Zam-City'
    },
    {
      path: '/cashouts',
      name: 'Cashouts',
      view: 'reports/Cashout'
    },
    {
      path: '/buy-credits',
      name: 'Buy Credits',
      view: 'reports/BuyCredits'
    },
    {
      path: '/2d-game-antique',
      name: 'STL-ANTIQUE',
      view: 'game-setup/2D-Peryahan'
    },
  ]
