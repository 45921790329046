<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="920">
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-account</v-icon><span class="va-underline">{{state=='new'?'New':'Edit'}} User</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text>
             <v-form
                ref="form"
                class="mt-4"
                v-model="valid"
                lazy-validation
              >
              <v-row>
                <v-col cols="6">
                  
               <v-text-field
                 v-model="user.fullname"
                  label="Full Name *"
                  outlined
                  dense
                  :rules="textRules"
                required
                ></v-text-field>

                <v-text-field
                 outlined
                  dense
                 v-model="user.username"
                  label="Username *"
                  :rules="textRules"
                required
                ></v-text-field>

                <v-text-field
                  v-model="user.password"
                  v-if="state=='new'" 
                  label="Password *"
                  :rules="textRules"
                required
                 outlined
                  dense
                ></v-text-field>
              <v-text-field
                  v-model="user.contact_no"
                   outlined
                  dense
                  label="Contact No. "
                ></v-text-field>
                <!-- <v-text-field
                  v-model="user.email"
                   outlined
                  dense
                  label="Email"
                ></v-text-field> -->
    <v-text-field
                  v-model="user.address"
                   outlined
                  dense
                  label="Address "
                ></v-text-field>
                 <v-text-field
                      v-model="newpassword"
                      outlined
                    dense
                      name="input-10-1"
                      type="password"
                      label="Change Password"
                        append-outer-icon="mdi-content-save-move"
                      @click:append-outer="ChangePassword()"
                      v-if="show1==true"
                    ></v-text-field>
                   <v-btn   v-if="state!='new'" @click="show1=true" color="darken-1" text small><v-icon left small>mdi-key-variant</v-icon> Change Password</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-autocomplete
                  v-model="user.area"
                  outlined
                    dense
                  :items="areas"
                  item-text="name"
                  item-value="id"
                  label="Cluster name"
                  ></v-autocomplete> 
                  
             
                  <v-autocomplete
                  v-model="user.barangay"
                  outlined
                    dense
                  :items="SELECTED_AREAS"
                  label="Zone"
                  ></v-autocomplete> 
                 
                   <v-autocomplete
                  v-model="user.role"
                   :rules="textRules"
                required
                  :items="roles"
                  outlined
                    dense
                  label="User type"
                  ></v-autocomplete>   

                  <template v-if="user.role == 'Coordinator'  || user.role == 'Agent' || user.role == 'Player'">
                    <v-autocomplete
                        v-model="user.coordinator"
                        outlined
                          dense
                        :items="COORDINATORS"
                        item-text="fullname"
                        item-value="id"
                        label="Select General Coordinator"
                        ></v-autocomplete>
                        <template v-if="user.role == 'Agent' || user.role == 'Player'">
                          <v-autocomplete
                          v-model="user.agent"
                          outlined
                            dense
                          :items="AGENTS"
                          item-text="fullname"
                          item-value="id"
                          label="Select Coordinator"
                          ></v-autocomplete>
                        </template>
                       <v-autocomplete
                        v-if="user.role == 'Player'"
                        v-model="user.sub_agent"
                        outlined
                          dense
                        :items="SUBAGENTS"
                        item-text="fullname"
                        item-value="id"
                        label="Select Agent"
                        ></v-autocomplete>
                  </template>
                         <template v-if="user.role == 'General Coordinator' || user.role == 'Coordinator'||user.role == 'Agent'">
                          <v-text-field
                    v-model="user.commission"
                    outlined
                    dense
                    label="Commision (%)"
                  ></v-text-field>
                         </template>
                     <v-autocomplete
                  v-model="user.status"
                  outlined
                    dense
                  :items="['Active', 'Deactivated']"
                  label="Status"
                  ></v-autocomplete>

                  <template v-if="user.role == 'Partner'">
                    <div class="pa-2">
                      <p class="text-h6">GAMES: </p>
                        <table class="va-table">
                          <tr v-for="(comm,i) in USERGAMES" :key="i">
                            <!-- <td style="width: 100px;" class="pa-2">{{comm.game}}</td>
                            <td class="pa-2"><input v-model="comm.comm"  style="width: 100px; text-align: center; border: 1px solid #555"/></td> -->
                            <td>
                              <v-checkbox
                              dense
                              :value="1"
                              hide-details
                              v-model="comm.comm"
                              :label="comm.game"
                              ></v-checkbox>
                            </td>
                          </tr>
                        </table>
                    </div>
                         </template>
                </v-col>
              </v-row>
             </v-form>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small rounded text @click="emitToParent('close')">CLOSE</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success"  depressed  @click="validate()">{{BTN_TEXT}}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    account: {
        type: Object,
        default: ()=>{ return {}}
    },
    users: {
        type: Array,
        default: ()=>[]
    },
    areas: {
      type:Array,
      default: ()=>{[]}
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      newpassword:'',
      user:{},
      selected_agent:{},
      show1: false,
      game_comm :[
          {game: '2D', comm:0},
          {game: '3D', comm:0},
          {game: '4D', comm:0},
          {game: 'STL-SILAY', comm:0},
          {game: '2D-STL-ILOILO-CITY', comm:0},
          {game: '3D-STL-ILOILO-CITY', comm:0},
          {game: 'STL-ILOILO-PROVINCE', comm:0},
          {game: '3D-ZAM-CITY', comm:0},
        ],
       roles: [ 'Administrator', 'Partner', 'General Coordinator', 'Coordinator', 'Agent', "Player"],
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
      BTN_TEXT() {
          if(this.state == 'new')
            return "ADD"
         else 
            return "SAVE"
      },
      COORDINATORS(){
        var arr = this.users.reduce((res, item)=>{
         // console.log(item)
          if(item.role== 'General Coordinator') {
           res.push(item)
          }
          return res
        }, [])
       // console.log(Barangays)
        return arr
      },
       AGENTS(){
        var arr = this.users.reduce((res, item)=>{
         // console.log(item)
          if(item.role== 'Coordinator') {
           res.push(item)
          }
          return res
        }, [])
       // console.log(Barangays)
        return arr
      },
       SUBAGENTS(){
        var arr = this.users.reduce((res, item)=>{
         // console.log(item)
          if(item.role== 'Agent') {
           res.push(item)
          }
          return res
        }, [])
       // console.log(Barangays)
        return arr
      },
      SUBAGENTS2(){
        var arr = this.users.reduce((res, item)=>{
         // console.log(item)
          if(item.role== 'Sub-Agent2') {
           res.push(item)
          }
          return res
        }, [])
       // console.log(Barangays)
        return arr
      },
      COLLECTORS(){
        var arr = this.users.reduce((res, item)=>{
         // console.log(item)
          if(item.role== 'Collector') {
           res.push(item)
          }
          return res
        }, [])
       // console.log(Barangays)
        return arr
      },
      SELECTED_AREAS(){
        
        var Barangays = this.areas.reduce((res, item)=>{
         // console.log(item)
          if(item.id== this.user.area) {
              if(item.areas != null) {
                for(var i=0; i<item.areas.length;i++) {
                  //console.log(item.areas[i])
                    res.push(item.areas[i].name)
                }
            }
          }
          return res
        }, [])
       // console.log(Barangays)
        return Barangays
      },
      SELECTED_MUN() {
        if(this.SELECTED_AREAS.Areas == undefined) {
          return {}
        }
           var municipal = this.SELECTED_AREAS.Areas.reduce((res, item)=>{
          if(item.name== this.user.municipal) {
            res = item
          }
          return res
        }, {})
        return municipal
      },
      USERGAMES() {
        return this.game_comm
      }
  },
  watch: {
    show (v) {
      if(v) {
        this.userDetails()
         this.user.password =''
      } else {
        this.user = {}
        this.newpassword = ''
        this.show1=false
         this.game_comm = [
          {game: '2D', comm:0},
          {game: '3D', comm:0},
          {game: '4D', comm:0},
          {game: 'STL-SILAY', comm:0},
          {game: '2D-STL-ILOILO-CITY', comm:0},
          {game: '3D-STL-ILOILO-CITY', comm:0},
          {game: 'STL-ILOILO-PROVINCE', comm:0},
          {game: '3D-ZAM-CITY', comm:0},
        ]
      }
     
    }
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.emitToParent(this.state)
          this.show1 = false
        }
      },
     userDetails(){
          this.$http.post("user/details",{id: this.account.id}).then(response => {
            console.log(response.data.account)
              if(response.data.status) {
                 this.user = response.data.account
                 if(this.user.game_comm.length == 0 || this.user.game_comm == null) {
                   this.user.game_comm = this.game_comm
                 } else {
                  this.game_comm = this.user.game_comm 
                 }
              } 
          }).catch(e => {
            console.log(e.data)
          })
    },
     ChangePassword: function() {
       var param = {}
       param.id = this.user.id
       param.password = this.newpassword
        this.$http.post("user/change_password", param).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message) : this.VA_ALERT('error', response.data.message) 
            
        }).catch(e => {
          console.log(e)
        })
     },
    emitToParent (action) {
      if(this.show1) {
        this.user.password = this.newpassword
      }
      // if(this.user.role=="Player") {
      //   this.user.agent = this.selected_agent.id
      //   this.user.coordinator = this.selected_agent.coordinator
      // }
      this.user.commission = this.$IsNum(this.user.commission)
      this.user.game_comm =  this.filterFloat()
      console.log(this.user)
      this.$emit('DialogEvent', {action: action, user:this.user})
    },
    filterFloat () {
      var comm = this.game_comm.reduce((res, item)=>{
          item.comm = this.$IsNum(item.comm)
          res.push(item)
          return res
      },[])
      return comm
    }
  }
}
</script>
