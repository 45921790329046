<template>
    <div v-if="show">
   
    <v-card dense>
        <v-toolbar>
             GEN. COORDINATOR PER GAME
            <v-spacer/>
            <span style="width: 270px; margin-top: 24px; margin-right: 7px;"> 
                    <v-autocomplete
                        :items="coordinators"
                        label="Select Gen. Coor"
                        item-text="fullname"
                          v-model="coordinator"
                        return-object
                        @change="getTransactions()"
                         dense
                         outlined
                    />
               </span>
               <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="Select Date"
                                 :value="from_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false, getTransactions()" />
                    </v-menu>
               </span>
                
               <v-icon right color="primary" @click="dlToCsv()">mdi-download</v-icon>
        </v-toolbar>
        <v-card-text>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
             <p class="text-center">
               GEN. COORDINATOR PER GAME<br />
                Date Covered: {{$moment(from_date).format('YYYY-MMM-DD')}} 
            </p>
            
            <table class="va-table def-font" id="daily_coordinator_summary">
                <tr>
                    <th colspan="8">GEN. COOR: {{coordinator.fullname}}</th>
                   
                </tr>
                <tr>
                    <th class="border_bot">GAME</th>
                  
                     <th class="border_bot">COMM(%)</th>
                    <th class="text-right border_bot">GROSS</th>
                    <th class="text-right border_bot">COMM</th>
                    <th class="text-right border_bot">HITS</th>
                </tr>
                <tr v-for="(item,index) in items" :key="index">
                    <td class="border_bot">{{item.game}}</td>
                    <td class="border_bot">{{game_comm(item.game)}}</td>
                    <td class="border_bot text-right">{{$money(item.total)}}</td>
                     <td class="border_bot text-right">{{$money(game_comm_amount(item))}}</td>
                    <td class="border_bot text-right">{{$money(item.win_amount)}}</td>
                </tr>
                  <tr>
                    <th></th>
                     <th class="text-right">TOTAL:</th>
                    <th class="text-right">{{$money(GROSS)}}</th>
                    <th class="text-right">{{$money(COMMISSION)}}</th>
                    <th class="text-right">{{$money(TOTALHITS)}}</th>
                </tr>
            </table>
           
        </v-card-text>     
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
      show:{
          type: Boolean,
          default: false
      }
  },
  data: () => ({
        loading: false,
        from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
        items:[],
        coordinators:[],
        coordinator:{},
       
    }),
    watch: {
        show(v){
            if(v) {
                 this.getCoordinators()
            }
        }
    },
    computed:{
        COMMISSION() {
            var total = this.items.reduce((res,item)=>res += this.game_comm_amount(item),0)
            return total
        },
        GROSS() {
                var total = this.items.reduce((res,item)=>res += this.$IsNum(item.total),0)
                return total
        },
         TOTALNET() {
           // var total = this.items.reduce((res,item)=>res += this.GROSS - this.COMMISSION,0)
            return this.GROSS - this.COMMISSION
        },
         TOTALHITS() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.win_amount),0)
                return total
        }
    },
  methods: {
      game_comm(_game){
          if(this.coordinator.game_comm == null){ return 0 }
          for(var i=0;i<this.coordinator.game_comm.length;i++){
              if(_game == this.coordinator.game_comm[i].game) {
                  
                  return this.coordinator.game_comm[i].comm?this.$IsNum(this.coordinator.game_comm[i].comm):0
              }
          }
          return 0
      },
       game_comm_amount(draw){
           var game_comm = this.game_comm(draw.game)!=0?this.game_comm(draw.game)/100:0
            return this.$IsNum(draw.total) * game_comm
        
      },
      getCoordinators(){
            this.loading = true
            this.coodinators =[]
             this.$http.post("user/get_coordinator").then(response => {
                response.data.coordinators !=null?this.coordinators = response.data.coordinators:this.coordinators =[]
               console.log(this.coordinators)
               this.loading = false
          }).catch(e => {
            console.log(e.data)
          })
        },
        getTransactions(){
            this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.from_date
            param.to_date = this.from_date
            param.account = parseInt(this.coordinator.id)
            param.Method = "per_game_gen_coordinator_summary"
            console.log(param)
             this.$http.post("report/transactions",param).then(response => {
                response.data.items !=null?this.items = response.data.items:this.items =[]
                console.log(response.data)
                this.loading = false
          }).catch(e => {
            console.log(e.data)
          })
        },
        dlToCsv(){
            this.DownloadToCsv("daily_coordinator_summary", 'Daily-coordinator-summary-' + this.from_date)
        }
  }
}
</script>
